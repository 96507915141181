/**
 *
 * @param originalPrice the product original price
 * @param discountedPrice the new price for the product (with the discount applied)
 * @returns a number with the discount rate
 */
export const getProductDiscountRate = (
  originalPrice = 0,
  discountedPrice = 0,
): number => {
  if (discountedPrice >= originalPrice) {
    return 0;
  }

  /**
   * Ensure that the discount rates are always rounded down
   * to avoid cases where the displayed rate is bigger than the
   * actual discount.
   */
  const discountRate = Number(1- discountedPrice / originalPrice);

  return Math.floor((discountRate + Number.EPSILON) * 100) / 100;
};

/**
 * NOTE https://www.jacklmoore.com/notes/rounding-in-javascript/
 * @param discount
 * @returns stringified rounded version
 *
 * If the amount is not even, fix to two decimals
 */
export const getDiscountDisplayValue = (discount: number): number =>
  Math.round(discount);
